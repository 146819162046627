.sider {
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  height: 100%;

  .logo {
    [class~="ant-menu-title-content"] {
      justify-content: center;
      margin-top: 40px;
      display: flex;
    }

    [class~="ant-menu-item"] {
      height: 110px;
    }
  }
}

.menu {
  margin-top: 20px;
  flex-grow: 2;
}

.layoutMain {
  min-height: 100vh;

  [class~="ant-menu-submenu-title"] {
    margin-left: 5px;

    [class~="ant-menu-title-content"] {
      margin-left: 22px;
    }
  }
}

.content {
  background: white !important;
  overflow: auto;
  height: 100vh;
  padding: 20px;
}
