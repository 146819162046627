[class~="ant-menu-inline-collapsed-tooltip"] {
  [class~="ant-tooltip-content"] {
    .iconAnt {
      line-height: 23px;
      margin-right: 9px;
      margin-left: 0;
    }

    .label {
      line-height: 23px;
    }

    .listItem {
      line-height: 23px;
      height: 23px;
    }
  }
}

[class~="ant-layout-sider-collapsed"] {
  .label {
    visibility: hidden;
  }
}

.label {
  line-height: 40px;
  height: 40px;
}

.listItem {
  display: flex;
  height: 40px;
}

.iconAnt {
  margin-right: 23px;
  margin-left: 3px;
}
