.root {
  z-index: 9999;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 10px 10px 20px;
  background-color: white;
  border-left: 0 0 1px solid grey;
  color: #555;
  border-bottom-left-radius: 5px;
  box-shadow: 2px 2px 5px grey;
}

.header {
  display: flex;
}

.settingsRoot {
  margin-top: 20px;
  padding: 0 20px 10px 10px;
}

.settingsRoot ul {
  padding: 0;
  margin: 0;
}

.settingsRoot li {
  list-style: none;
}
