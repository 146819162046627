body {
  overflow: hidden;
}

/* stylelint-disable */ 
.App main {
  margin: 30px 20px;
}

.navigation-icon {
  color: white !important;
}

a {
  color: teal;
  cursor: pointer;
  display: inline-block;
  padding: 8px;
  margin: -8px;
  background: transparent;
  transition: all 0.25s;
  border-radius: 2px;
  font-weight: 500;
}

a:hover {
  background: rgb(38 166 154 / 12%);
}

fieldset {
  border: 0;
  padding: 0;
}
