.list {
  padding-top: 0;

  li {
    padding-left: 0;
  }
}

.noPayments {
  padding: 0;
}

button.listItem {
  width: 100%;
  padding-left: 12px;
  border: 1px solid #bfbfbf;
  margin-bottom: 12px;
  text-transform: capitalize;

  &:hover {
    color: #26a69a;
  }
}

.dateRangeWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.dateRange {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
}

.form {
  min-width: 340px;
  min-height: 340px;

  label {
    top: -8px;
  }
}
