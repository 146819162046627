@mixin dashed-border {
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 2px;
    background-color: white;
    left: -29px;
  }

  &::before {
    top: -19px;
    height: 33%;
  }

  &::after {
    top: 29px;
    height: 55%;
  }
}

.draftCreatedEvent {
  @include dashed-border;

  &::before,
  &::after {
    border-left: 2px dashed #40a9ff;
  }
}

.draftDiscardedEvent {
  @include dashed-border;

  &::before,
  &::after {
    border-left: 2px dashed #bfbfbf;
  }
}

.subTimeLineWrapper {
  left: -22px;
}
